// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("iconfont.woff2?t=1689321474693", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("iconfont.woff?t=1689321474693", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("iconfont.ttf?t=1689321474693", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:font_family;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.font_family{font-family:font_family!important;font-size:16px;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-jiaoxuepingjia:before{content:\"\\e63c\"}.icon-kaoshiguanli:before{content:\"\\e63e\"}.icon-kechengguanli:before{content:\"\\e63f\"}.icon-ketangguanli:before{content:\"\\e640\"}.icon-xiaoneifuwu:before{content:\"\\e641\"}.icon-zhihuijiaoyan:before{content:\"\\e642\"}.icon-xiaoyuanxungeng:before{content:\"\\e643\"}.icon-xiaoyuanshenghuo:before{content:\"\\e644\"}.icon-xiaoyuananbao:before{content:\"\\e645\"}.icon-zhihuizuoye:before{content:\"\\e646\"}.icon-anquanjiaoyu:before{content:\"\\e647\"}.icon-sushechaqin:before{content:\"\\e648\"}.icon-zhaokaolu:before{content:\"\\e649\"}.icon-tushuguanli:before{content:\"\\e63d\"}.icon-deyuguanli:before{content:\"\\e639\"}.icon-renyuanxinxi:before{content:\"\\e63a\"}.icon-jichushuju:before{content:\"\\e63b\"}.icon-xietongbangong:before{content:\"\\e637\"}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
